import React from "react";
import LoRCardGallery from "../../components/LoR/LoRCardGallery";
import { Set1Card, cardArrayCollectible } from "../../components/LoR/LoRCard";
import { useLoRContext } from "../../contexts/LoRContext";
import SpinningPage from "./SpinningPage";

interface Props {}

const LoRCardGalleryPage = (props: Props) => {
  //   const { filteredCards } = useSelector((state: RootState) => state.lor.set1);

  const {
    initialized,
    loR: { filteredCards },
  } = useLoRContext();
  if (!initialized) {
    return <SpinningPage />;
  }

  return <LoRCardGallery cards={filteredCards}></LoRCardGallery>;
};

export default LoRCardGalleryPage;
