import React, { PropsWithChildren } from "react";
import { Layout } from "antd";

const { Header, Footer, Content } = Layout;

interface Props {}

const HeaderFooterLayout = (props: PropsWithChildren<Props>) => {
  return (
    <Layout style={{ height: "100vh", textAlign: "center" }}>
      <Header>
        <h1>Welcome to Stay Hacking</h1>
      </Header>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </Content>
      <Footer>
        Made with{" "}
        <span role="img" aria-label="Cheese">
          🧀
        </span>{" "}
        by
        <a href="https://github.com/matthewredmongross"> Matt Gross</a>
      </Footer>
    </Layout>
  );
};

export default HeaderFooterLayout;
