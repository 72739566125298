import React from "react";
import { Form, Input, Button } from "antd";

import { LockOutlined, UserOutlined } from "@ant-design/icons";

interface Props {
  style: React.CSSProperties;
  onSubmit: (values: { email: string; password: string }) => void;
}

const NewUserForm = (props: Props) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    props.onSubmit(values);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      style={{ width: "400px", ...props.style }}
      initialValues={{}}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input an email." }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input a password.",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          // type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item style={{ textAlign: "center" }}>
        <Button size="large" type="primary" htmlType="submit">
          Create new user
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NewUserForm;
