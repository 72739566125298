import React from "react";
import { Form, Input, Button } from "antd";

interface Props {
  style: React.CSSProperties;
  loading: boolean;
  onSubmit: (values: { name: string }) => void;
}

const NewRoomForm = (props: Props) => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    props.onSubmit(values);
  };

  return (
    <Form
      style={{ width: "400px", ...props.style }}
      initialValues={{}}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input a room name" }]}
      >
        <Input placeholder="Room Name" />
      </Form.Item>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          loading={props.loading}
          size="large"
          type="primary"
          htmlType="submit"
        >
          Create new room
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NewRoomForm;
