import React from "react";
import Router from "./containers/Router";
import { AuthProvider } from "./contexts/AuthContext";

// Make sure this is the last thing imported in order to override antd styles
import "./App.css";
import { AppProvider } from "./contexts/AppContext";
import { Button } from "antd";
import Axios from "axios";
function App() {
  console.log("App");

  // const test = async () => {
  //   console.log("test");
  //   const response = await Axios.get(`/api/`);
  //   console.log("response :", response);
  // };

  return (
    <AppProvider>
      <AuthProvider>
        {/* <Button onClick={test}>Test</Button> */}
        <Router></Router>
      </AuthProvider>
    </AppProvider>
  );
}

export default App;
