import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import produce from "immer";
import Axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useAuthContext } from "./AuthContext";
import { useHistory } from "react-router-dom";
import { message as antdmessage } from "antd";
import firebase from "firebase";
// Retrieve Firebase Messaging object.
console.log("init messaging");
const messaging = firebase.messaging();
messaging.usePublicVapidKey(
  "BLmuYkuGk9Up1nS8TfdPAGl-bPItk-7wKnv1geju5EvTifUDXDg8wH7OJJ6ueNC7YgMXa9dpknPxg7iB3JK11XU"
);

interface Message {
  uid: string;
  from: string;
  text: string;
  dateSent: {
    _seconds: number;
    _nanoseconds: number;
  };
}

interface Member {
  uid: string;
  email: string;
  role: string;
  displayName?: string;
  photoURL?: string;
}

interface Role {
  uid: string;
  name: string;
  canSendMessage: boolean;
  rank: number;
  canAddMember: boolean;
  canDeleteRoom: boolean;
}

export interface Room {
  uid: string;
  name: string;
  members: { [key: string]: Member };
  roles: { [key: string]: Role };
  messages: Message[];
  messageCapacity?: number;
}

interface State {
  initialized: boolean;
  rooms: { [key: string]: Room };
  fetchRoom: (roomUid: string) => Promise<void>;
  sendMessage: (roomUid: string, message: { text: string }) => Promise<void>;
  addMember: (roomUid: string, memberUid: string) => Promise<void>;
  addRoom: (values: { name: string }) => Promise<void>;
}

const initialRooms: { [key: string]: Room } = {};

const RoomsContext = createContext<State | undefined>(undefined);

const RoomsProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [rooms, setRooms] = useState(initialRooms);
  const AuthContext = useAuthContext();
  const {
    auth: { token, currentUser },
  } = AuthContext;

  const history = useHistory();

  const fetchRoom = useCallback(
    async (roomUid: string) => {
      // console.log("fetch room: " + roomUid);
      try {
        const response = await Axios.get(`/api/rooms/${roomUid}`, {
          headers: { Authorization: "Bearer " + token },
        });
        setRooms((curRooms) =>
          produce(curRooms, (draft) => {
            draft[roomUid] = {
              ...curRooms[roomUid],
              ...response.data,
            };
          })
        );
      } catch (error) {
        const errJSON = error.toJSON();
        console.error(errJSON);
        if (error.response) {
          antdmessage.error(error.response.data);
        } else {
          antdmessage.error(errJSON.message);
        }
      }
    },
    [token]
  );

  const fetchRooms = useCallback(async () => {
    // console.log("fetchRooms");
    try {
      const response = await Axios.get(`/api/rooms`, {
        headers: { Authorization: "Bearer " + token },
      });

      setRooms((curRooms) =>
        produce(curRooms, (draft) => {
          for (const room of response.data as Room[]) {
            draft[room.uid] = { ...curRooms[room.uid], ...room };
          }
        })
      );

      if (response.data.length > 0) {
        fetchRoom(response.data[0].uid);
        history.push(`/rooms/${response.data[0].uid}`);
      }
      return true;
    } catch (error) {
      const errJSON = error.toJSON();
      console.error(errJSON);
      if (error.response) {
        antdmessage.error(error.response.data);
      } else {
        antdmessage.error(errJSON.message);
      }
      return false;
    }
  }, [token, fetchRoom, history]);

  const sendMessage = async (roomUid: string, message: { text: string }) => {
    const tempUid = uuidv4();
    const idx = rooms[roomUid].messages!.length;
    const tempMessage = {
      uid: tempUid,
      from: currentUser!.uid,
      text: message.text,
      dateSent: {
        _seconds: moment().unix(),
        _nanoseconds: 0,
      },
    };

    setRooms((curRooms) =>
      produce(curRooms, (draft) => {
        draft[roomUid].messages.push(tempMessage);
      })
    );

    try {
      const response = await Axios.post(
        `/api/rooms/${roomUid}/messages`,
        message,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );

      setRooms((curRooms) =>
        produce(curRooms, (draft) => {
          draft[roomUid].messages[idx] = response.data;
        })
      );
    } catch (error) {
      const errJSON = error.toJSON();
      console.error(errJSON);
      if (error.response) {
        antdmessage.error(error.response.data);
      } else {
        antdmessage.error(errJSON.message);
      }
    }
  };

  const addMember = async (roomUid: string, memberUid: string) => {
    console.log(
      "addMember, roomUid, memberUid :",
      addMember,
      roomUid,
      memberUid
    );
    try {
      const response = await Axios.post(
        `/api/rooms/${roomUid}/members`,
        { uid: memberUid, role: "Member" },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      if (response.status === 201) {
        setRooms((curRooms) =>
          produce(curRooms, (draft) => {
            draft[roomUid].members[memberUid] = response.data;
          })
        );
      }
    } catch (error) {
      const errJSON = error.toJSON();
      console.error(errJSON);
      if (error.response) {
        antdmessage.error(error.response.data);
      } else {
        antdmessage.error(errJSON.message);
      }
    }
  };

  const addRoom = async (values: { name: string }) => {
    try {
      const response = await Axios.post(`/api/rooms`, values, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log("response :", response);
      if (response.status === 201) {
        console.log("Successfully created new room");
        const newRoom = response.data;
        setRooms((curRooms) =>
          produce(curRooms, (draft) => {
            draft[newRoom.uid] = newRoom;
          })
        );

        history.push(`/rooms/${response.data.uid}`);

        const t = await messaging.getToken();
        console.log("t :", t);
        if (t) {
          await sendTokenToServer(t, true);
        }
      }
    } catch (error) {
      const errJSON = error.toJSON();
      console.error(errJSON);
      if (error.response) {
        antdmessage.error(error.response.data);
      } else {
        antdmessage.error(errJSON.message);
      }
    }
  };

  const sendTokenToServer = async (currentToken: string, force = true) => {
    if (!isTokenSentToServer() || force) {
      console.log("Sending token to server...");
      console.log("currentToken :", currentToken);
      try {
        const response = await Axios.post(
          `/api/rooms/token`,
          { token: currentToken },
          {
            headers: { Authorization: "Bearer " + token },
          }
        );
        console.log("response", response);
      } catch (error) {
        const errJSON = error.toJSON();
        console.error(errJSON);
        if (error.response) {
          antdmessage.error(error.response.data);
        } else {
          antdmessage.error(errJSON.message);
        }
      }
      setTokenSentToServer(true);
    } else {
      console.log(
        "Token already sent to server so won't send it again " +
          "unless it changes"
      );
    }
  };

  const handleNewMessage = async (payload: any) => {
    console.log("handleNewMessage :", payload);
    const { code, roomUid, message } = payload.data;
    if (code === "newmessage") {
      const messageData = JSON.parse(message);
      if (messageData.from !== currentUser?.uid) {
        setRooms((curRooms) =>
          produce(curRooms, (draft) => {
            draft[roomUid].messages.push(messageData);
          })
        );
      }
    }
  };

  useEffect(() => {
    const run = async () => {
      console.log("fetching rooms");
      const success = await fetchRooms();
      console.log("success :", success);
      if (success) {
        console.log("registering on token refresh");
        const t = await messaging.getToken();
        console.log("t :", t);
        if (t) {
          await sendTokenToServer(t);
          setTokenSentToServer(true);
          setInitialized(true);
        }
        // messaging.onTokenRefresh(async () => {
        //   console.log("onTokenRefresh triggered");
        //   const refreshedToken = await messaging.getToken();
        //   console.log("Token refreshed.");
        //   console.log("refreshedToken :", refreshedToken);
        //   // Indicate that the new Instance ID token has not yet been sent to the
        //   // app server.
        //   setTokenSentToServer(false);
        //   // Send Instance ID token to app server.
        //   console.log("calling sendTokenToServer");
        //   await sendTokenToServer(refreshedToken);
        //   setInitialized(true);
        // });

        messaging.onMessage(handleNewMessage);
        // if (t) {
        //   setInitialized(true);
        // }
      }
    };
    if (!initialized && AuthContext.initialized) {
      run();
    }
  }, [initialized, AuthContext.initialized, fetchRooms]);

  const value = {
    initialized,
    rooms,
    fetchRoom,
    sendMessage,
    addMember,
    addRoom,
  };

  return <RoomsContext.Provider value={value} {...props} />;
};

function useRoomsContext() {
  return useContext(RoomsContext)!;
}

function isTokenSentToServer() {
  return window.localStorage.getItem("sentToServer") === "1";
}

function setTokenSentToServer(sent: boolean) {
  window.localStorage.setItem("sentToServer", sent ? "1" : "0");
}

export { RoomsProvider, useRoomsContext };
