import React from "react";
import SignInForm from "../../components/SignInForm";
import firebase from "firebase";

interface Props {}

const SignInPage = (props: Props) => {
  const onSubmit = async (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password);
  };

  const onSignInWithGoogleClicked = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <SignInForm
      style={{ margin: "auto" }}
      onSubmit={onSubmit}
      onSignInWithGoogleClicked={onSignInWithGoogleClicked}
    ></SignInForm>
  );
};

export default SignInPage;
