import React from "react";

const Code = (props: any) => {
  return (
    <textarea
      style={{ fontSize: "8px", width: "100%", minHeight: "200px" }}
      readOnly
      value={JSON.stringify(props, undefined, 2)}
    ></textarea>
  );
};

export default Code;
