import React, { useState, useEffect } from "react";
import { Modal, Select, Button } from "antd";
// import useAuth from "../hooks/useAuth";
import Axios from "axios";
import { useAuthContext } from "../contexts/AuthContext";
import { debounce } from "../util";
import useDebounce from "../hooks/useDebounce";

const { Option } = Select;

interface Member {
  uid: string;
  role: string;
  photoURL?: string;
}

interface Props {
  roomUid: string;
  visible: boolean;
  onCancel: () => void;
  onAddMember: (memberUid: string) => Promise<void>;
}

const AddMemberModal = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();
  const debouncedSearchText = useDebounce(searchText, 600);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState<string | undefined>(
    undefined
  );

  const {
    auth: { token },
  } = useAuthContext();

  useEffect(() => {
    let searchUsers = async (text: string) => {
      const response = await Axios.post(
        `/api/users/search`,
        { searchText: text },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      if (response.status === 200) {
        // console.log("response.data", response.data);
        setFilteredMembers(response.data.slice(0, 10));
      } else {
        console.log("not 200");
      }
      setLoading(false);
    };
    if (debouncedSearchText !== undefined && debouncedSearchText !== "") {
      setLoading(true);
      searchUsers(debouncedSearchText);
    }
  }, [debouncedSearchText]);

  function onChange(value: string, option: any) {
    // console.log("onChange", value);
    setSelectedMember(value);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  const onSearch = async (text: string) => {
    // console.log("onSearch");
    setSearchText(text);
  };

  const onOk = async () => {
    setConfirmLoading(true);
    await props.onAddMember(selectedMember!);
    setSearchText(undefined);
    setSelectedMember(undefined);
    setFilteredMembers([]);
    setConfirmLoading(false);
  };

  const onCancel = () => {
    // console.log("handleOnCancel");
    setSearchText(undefined);
    setSelectedMember(undefined);
    setFilteredMembers([]);
    props.onCancel();
  };

  return (
    <Modal
      title="Add member"
      visible={props.visible}
      // onOk={onOk}
      okText="Add"
      // onCancel={onCancel}
      confirmLoading={confirmLoading}
      footer={[
        <Button key="back" onClick={onCancel} loading={confirmLoading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={onOk}
        >
          Add
        </Button>,
      ]}
    >
      <Select
        showSearch
        loading={searchText !== debouncedSearchText}
        style={{ width: "100%" }}
        placeholder="Input a name or email"
        // value={selectedMember ? selectedMember : searchText}
        optionFilterProp="children"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onSearch={onSearch}
      >
        {filteredMembers.map(({ uid, email, displayName, photoURL }) => {
          return (
            <Option key={uid} value={uid}>
              {photoURL ? (
                <img height="20px" src={photoURL} alt="avatar" />
              ) : null}{" "}
              {displayName ? email + ` (${displayName})` : email}
            </Option>
          );
        })}
      </Select>
    </Modal>
  );
};

export default AddMemberModal;
