import React, { FunctionComponent } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  Redirect,
} from "react-router-dom";
import HeaderFooterLayout from "./layouts/HeaderFooterLayout";
import {
  SignUpPage,
  SignInPage,
  ForgotPasswordPage,
  ExamplePage,
  SiderLayout,
} from ".";
import UsersPage from "./pages/UsersPage";
import UserPage from "./pages/UserPage";
import SpinningPage from "./pages/SpinningPage";
// import useAuth, { AuthState } from "../hooks/useAuth";
import {
  UserOutlined,
  TeamOutlined,
  WechatOutlined,
  PlusOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import NewRoomPage from "./pages/NewRoomPage";
import RoomsPage from "./pages/RoomsPage";
import NewUserPage from "./pages/NewUserPage";
import TestingPage from "./pages/TestingPage";
import LoRCardGalleryPage from "./pages/LoRCardGalleryPage";
import { useAuthContext, AuthState } from "../contexts/AuthContext";
import { RoomsProvider } from "../contexts/RoomsContext";
import { LoRProvider } from "../contexts/LoRContext";

export interface RouteConfig {
  path: string;
  redirect?: string;
  component?: FunctionComponent<any>;
  props?: any;
  layout?: FunctionComponent;
  icon?: FunctionComponent;
  authenticatedRequired: boolean;
  adminRequired: boolean;
  params?: any;
  displayName?: string;
}

export const routesConfig: RouteConfig[] = [
  {
    path: "/signIn",
    component: SignInPage,
    layout: HeaderFooterLayout,
    authenticatedRequired: false,
    adminRequired: false,
  },
  // {
  //   path: "/signUp",
  //   component: SignUpPage,
  //   layout: HeaderFooterLayout,
  //   authenticatedRequired: false,
  //   adminRequired: false,
  // },
  {
    path: "/forgot",
    component: ForgotPasswordPage,
    layout: HeaderFooterLayout,
    authenticatedRequired: false,
    adminRequired: false,
  },
  {
    path: "/users/new",
    component: NewUserPage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: true,
    icon: PlusOutlined,
    displayName: "Create New User",
  },
  {
    path: "/users/:uid",
    component: UserPage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: false,
    icon: UserOutlined,
    params: {
      uid: "me",
    },
    displayName: "My Profile",
  },
  {
    path: "/users",
    component: UsersPage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: true,
    icon: TeamOutlined,
    displayName: "Users",
  },
  {
    path: "/rooms/new",
    component: NewRoomPage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: false,
    icon: PlusOutlined,
    displayName: "Create New Room",
  },
  {
    path: "/rooms",
    component: RoomsPage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: false,
    icon: WechatOutlined,
    displayName: "Rooms",
  },
  {
    path: "/example",
    component: ExamplePage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: true,
    displayName: "Example",
  },
  {
    path: "/testing",
    component: TestingPage,
    authenticatedRequired: false,
    adminRequired: false,
  },
  {
    path: "/lor/gallery",
    component: LoRCardGalleryPage,
    layout: SiderLayout,
    authenticatedRequired: true,
    adminRequired: false,
    icon: CreditCardOutlined,
    displayName: "LoR Gallery",
  },
  {
    path: "/",
    redirect: "/users",
    authenticatedRequired: true,
    adminRequired: true,
  },
  {
    path: "/",
    redirect: "/users/me",
    authenticatedRequired: true,
    adminRequired: false,
  },
  {
    path: "/",
    redirect: "/signIn",
    authenticatedRequired: false,
    adminRequired: false,
  },
];

interface Props {}

const MyRouter = (props: Props) => {
  console.log("MyRouter");

  const { initialized, auth } = useAuthContext();
  const { currentUser, claims } = auth;
  if (!initialized) {
    return <SpinningPage />;
  }

  const authenticated = currentUser !== undefined;
  const admin = claims && claims.admin;

  const routes = routesConfig.map((rc, i) => createRoute(rc, auth));

  const routeLinks = routesConfig.map((routeConfig, i) => {
    if (routeConfig.authenticatedRequired !== authenticated) {
      return undefined;
    }

    if (routeConfig.adminRequired && !admin) {
      return undefined;
    }

    const to = getRouteConfigTo(routeConfig);
    return (
      <li key={i}>
        <NavLink
          style={{ color: "gray" }}
          activeStyle={{ color: "red" }}
          to={to}
        >
          {to}{" "}
          <span style={{ color: "blue" }}>
            ({routeConfig.redirect ? routeConfig.redirect : routeConfig.path})
          </span>
        </NavLink>
      </li>
    );
  });

  printRoutes(routes);

  return (
    <Router>
      {/* <Debug /> */}
      <LoRProvider>
        <RoomsProvider>
          {/* <nav>
        <ul>{routeLinks}</ul>
      </nav> */}
          <Switch>{routes}</Switch>
        </RoomsProvider>
      </LoRProvider>
    </Router>
  );
};

export const printRoutes = (routes: (JSX.Element | undefined)[]) => {
  for (const r of routes) {
    if (r) {
      console.log(r.key);
    }
  }
};

export const getRouteConfigTo = (routeConfig: RouteConfig) => {
  let to = routeConfig.path;
  if (routeConfig.params !== undefined) {
    for (const [k, v] of Object.entries(routeConfig.params)) {
      to = to.replace(":" + k, v as string);
    }
  }
  return to;
};

export const createRoute = (routeConfig: RouteConfig, auth: AuthState) => {
  let key = routeConfig.path;
  const { currentUser, claims } = auth;

  const authenticated = currentUser !== undefined;
  const admin = claims && claims.admin;

  if (routeConfig.authenticatedRequired !== authenticated) {
    return undefined;
  }

  if (routeConfig.adminRequired && !admin) {
    return undefined;
  }

  if (routeConfig.redirect !== undefined) {
    return (
      <Route key={key} path={routeConfig.path}>
        <Redirect to={routeConfig.redirect} />
      </Route>
    );
  }

  if (routeConfig.component === undefined) {
    return (
      <Route key={key} path={routeConfig.path}>
        <Redirect to="/" />
      </Route>
    );
  }

  if (routeConfig.layout !== undefined) {
    return (
      <Route key={key} path={routeConfig.path}>
        <routeConfig.layout>
          <routeConfig.component {...routeConfig.props}></routeConfig.component>
        </routeConfig.layout>
      </Route>
    );
  }

  return (
    <Route key={key} path={routeConfig.path}>
      <routeConfig.component {...routeConfig.props}></routeConfig.component>
    </Route>
  );
};

export default MyRouter;
