import React from "react";
import { Descriptions } from "antd";
import { Link } from "react-router-dom";

interface Props {
  style?: React.CSSProperties;
  user: { [key: string]: string | boolean };
}

const Profile = (props: Props) => {
  const title = props.user.displayName
    ? props.user.displayName
    : props.user.email;

  return (
    <Descriptions
      layout="vertical"
      style={{
        ...props.style,
        tableLayout: "fixed",
      }}
      title={
        <h2>
          <Link to={`/users/${props.user.uid}`} style={{ color: "blue" }}>
            {title}
          </Link>
          's Profile
        </h2>
      }
      bordered
    >
      {Object.entries(props.user).map(([k, v], i) => {
        return (
          <Descriptions.Item
            style={{ overflowWrap: "break-word" }}
            key={i}
            label={
              <span style={{ fontWeight: "bold" }}>{k.toUpperCase()}</span>
            }
          >
            {v.toString()}
          </Descriptions.Item>
        );
      })}
    </Descriptions>
  );
};

export default Profile;
