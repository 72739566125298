import React from "react";
import { List } from "antd";
import LoRCard, { Set1Card } from "./LoRCard";
import { LoRCard as LoRCardType } from "../../contexts/LoRContext";

interface Props {
  cards: LoRCardType[];
}

const LoRCardGallery = ({ cards }: Props) => {
  console.log("sortedCards :", cards);
  return (
    <div>
      <List
        grid={{
          xs: 2,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 6,
          xxl: 8,
        }}
        dataSource={cards}
        renderItem={(curCard) => (
          <List.Item style={{ textAlign: "center" }}>
            <LoRCard card={curCard}></LoRCard>
          </List.Item>
        )}
      />
    </div>
  );
};

export default LoRCardGallery;
