import React from "react";
import { Form, Input, Checkbox, Button } from "antd";

import {
  LockOutlined,
  UserOutlined,
  GoogleSquareFilled,
} from "@ant-design/icons";

interface Props {
  style: React.CSSProperties;
  onSubmit: (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => void;
  onSignInWithGoogleClicked: () => void;
}

const SignInForm = (props: Props) => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    props.onSubmit(values);
  };

  return (
    <Form
      style={{ width: "400px", ...props.style }}
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email." }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>
      <a className="login-form-forgot" href="forgot">
        Forgot password
      </a>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Sign in
        </Button>
        {/* <span> or </span>
        <Button
          size="large"
          type="primary"
          // disabled={isLoading}
          onClick={props.onSignInWithGoogleClicked}
          // className={styles.signInWithGoogleFormButton}
        >
          <GoogleSquareFilled />
          Sign In With Google
        </Button>
        <div>
          Need an account? <a href="signUp">Click here</a>
        </div> */}
      </Form.Item>
    </Form>
  );
};

export default SignInForm;
