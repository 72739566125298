import React, { ReactElement, useState } from "react";
import { Modal, Descriptions, Tag } from "antd";

import Set1JSON from "./set1-en_us.json";
import Set1GlobalsJSON from "./globals-en_us.json";
import { LoRCard as LoRCardType } from "../../contexts/LoRContext";
import Code from "../Code";
import Keyword from "./Keyword";

export const cardArrayCollectible = [...Set1JSON].reduce((acc, cur) => {
  if (cur.collectible) {
    acc.push(cur);
  }
  return acc;
}, [] as any[]);

// Map of all Cards indexed by cardCode
export const cardMap = [...Set1JSON].reduce((total, currentCard) => {
  total[currentCard.cardCode] = currentCard;
  return total;
}, Object.create({}) as { [key: string]: any });

// Array of all Regions
export const regions = Set1GlobalsJSON.regions.reduce(
  (total, currentRegion) => {
    total.push(currentRegion.nameRef);
    return total;
  },
  [] as string[]
);

export enum RegionName {
  Noxus = "Noxus",
  Demacia = "Demacia",
  Freljord = "Freljord",
  ShadowIsles = "ShadowIsles",
  Ionia = "Ionia",
  PiltoverZaun = "PiltoverZaun",
}

export const rarities = Set1GlobalsJSON.rarities.reduce((total, cur) => {
  total.push(cur.nameRef);
  return total;
}, [] as string[]);

export enum Rarity {
  Common = "Common",
  Rare = "Rare",
  Epic = "Epic",
  Champion = "Champion",
  None = "None",
}

export type RarityStrings = keyof typeof Rarity;

export enum CardType {
  Unit = "Unit",
  Spell = "Spell",
  Ability = "Ability",
}

export enum KeywordName {
  Obliterate = "Obliterate",
  Skill = "Skill",
  Weakest = "Weakest",
  Elusive = "Elusive",
  Drain = "Drain",
  Stun = "Stun",
  Trap = "Autoplay",
  "Double Attack" = "DoubleAttack",
  "Piltover & Zaun" = "PiltoverZaun",
  Demacia = "Demacia",
  "Shadow Isles" = "ShadowIsles",
  "Spell Overwhelm" = "SpellOverwhelm",
  "Barrier" = "Barrier",
  "Capture" = "Capture",
  Frostbite = "Frostbite",
  Burst = "Burst",
  Fleeting = "Fleeting",
  Fast = "Fast",
  Overwhelm = "Overwhelm",
  "Quick Attack" = "QuickStrike",
  Tough = "Tough",
  Recall = "Recall",
  Ionia = "Ionia",
  Regeneration = "Regeneration",
  Lifesteal = "Lifesteal",
  Enlightened = "Enlightened",
  Slow = "Slow",
  Noxus = "Noxus",
  Ephemeral = "Ephemeral",
  Freljord = "Freljord",
  "Last Breath" = "LastBreath",
  Challenger = "Challenger",
  Imbue = "Imbue",
  Fearsome = "Fearsome",
}

export enum SortValue {
  name = "name",
  rarity = "rarity",
  cost = "cost",
  attack = "attack",
  health = "health",
  // type = "type",
  region = "region",
}

export const sortCardArray = (
  array: any[],
  sortedBy: SortValue,
  sortAsc: boolean
) => {
  // Sort by SortValue value
  switch (sortedBy) {
    case SortValue.rarity:
      array.sort((a, b) => {
        if (
          Rarity[a.rarity as RarityStrings] < Rarity[b.rarity as RarityStrings]
        ) {
          return -1;
        } else if (a[sortedBy] === b[sortedBy]) {
          return 0;
        } else {
          return 1;
        }
      });
      break;
    default:
      array.sort((a, b) => {
        if (a[sortedBy] > b[sortedBy]) {
          return -1;
        } else if (a[sortedBy] === b[sortedBy]) {
          return 0;
        } else {
          return 1;
        }
      });
      break;
  }

  if (sortAsc) {
    array.reverse();
  }
  return array;
};

export type Set1Card = {
  associatedCards: [];
  associatedCardRefs: [];
  assets: [
    {
      gameAbsolutePath: string;
      fullAbsolutePath: string;
    }
  ];
  region: keyof typeof RegionName;
  regionRef: RegionName;
  attack: number;
  cost: number;
  health: number;
  description: string;
  descriptionRaw: string;
  levelupDescription: string;
  levelupDescriptionRaw: string;
  flavorText: string;
  artistName: string;
  name: string;
  cardCode: string;
  keywords: (keyof typeof KeywordName)[];
  keywordRefs: KeywordName[];
  spellSpeed: string;
  spellSpeedRef: string;
  rarity: keyof typeof Rarity;
  rarityRef: Rarity;
  subtype: string;
  supertype: string;
  type: string;
  collectible: boolean;
};

interface Props {
  card: LoRCardType;
}

export default function LoRCard({ card }: Props): ReactElement {
  const [modalTitle, setModalTitle] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(<div />);

  const handleClick = (curCard: LoRCardType) => {
    console.log("handle clicked called");

    const a =
      process.env.PUBLIC_URL +
      "/lor/regions/icon-" +
      curCard.regionRef.toLowerCase() +
      ".png";
    console.log("a :", a);
    setModalTitle(curCard.name);
    const newModalContent = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", marginRight: "20px" }}>
          <img
            // width="100%"
            height="300px"
            src={
              process.env.PUBLIC_URL + "/lor/cards/" + curCard.cardCode + ".png"
            }
            alt={curCard.name}
          ></img>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="CardCode">
              {curCard.cardCode}
            </Descriptions.Item>
            <Descriptions.Item label="Cost">
              <div className="cost-snippet">{curCard.cost}</div>
            </Descriptions.Item>
            {curCard.type === CardType.Unit ? (
              <Descriptions.Item
                label={
                  <span>
                    Attack<span style={{ float: "right" }}>Health</span>
                  </span>
                }
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="attack-snippet">{curCard.attack}</div>
                  <div className="health-snippet">{curCard.health}</div>
                </div>

                {/* <span style={{ float: "right" }}>{curCard.health}</span> */}
              </Descriptions.Item>
            ) : undefined}
            {curCard.keywords.length > 0 ? (
              <Descriptions.Item label="Keywords">
                {curCard.keywordRefs.map((kw) => {
                  return <Keyword keyword={kw} />;
                })}
              </Descriptions.Item>
            ) : undefined}
            {curCard.descriptionRaw ? (
              <Descriptions.Item label="Description">
                {curCard.descriptionRaw}
              </Descriptions.Item>
            ) : undefined}
            <Descriptions.Item label="Type">{curCard.type}</Descriptions.Item>
            {curCard.regionRef ? (
              <Descriptions.Item label="Region" style={{ lineHeight: "30px" }}>
                <img
                  height="30px"
                  src={
                    process.env.PUBLIC_URL +
                    "/lor/regions/icon-" +
                    curCard.regionRef.toLowerCase() +
                    ".png"
                  }
                />{" "}
                {curCard.region}
              </Descriptions.Item>
            ) : undefined}
            {curCard.rarityRef ? (
              <Descriptions.Item label="Region" style={{ lineHeight: "30px" }}>
                {curCard.rarityRef !== "None" ? (
                  <img
                    height="30px"
                    src={
                      process.env.PUBLIC_URL +
                      "/lor/rarities/" +
                      curCard.rarityRef +
                      ".png"
                    }
                  />
                ) : undefined}{" "}
                {curCard.rarity}
              </Descriptions.Item>
            ) : undefined}
            {curCard.flavorText ? (
              <Descriptions.Item label="FlavorText">
                <p>{curCard.flavorText}</p>
              </Descriptions.Item>
            ) : undefined}

            <Descriptions.Item label="Raw">
              <Code {...curCard}></Code>
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div style={{ marginTop: "10px" }}>
          <img
            // height="300px"
            width="100%"
            src={
              process.env.PUBLIC_URL +
              "/lor/cards/" +
              curCard.cardCode +
              "-full.png"
            }
            alt={curCard.name}
          ></img>
        </div>
      </div>
    );
    setModalContent(newModalContent);
    setModalVisible(true);
  };

  const handleOk = () => {
    console.log("handleOk");
  };

  const handleCancel = () => {
    console.log("handleCancel");
    setModalVisible(false);
  };

  const modal = (
    <Modal
      title={modalTitle}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={undefined}
    >
      {modalContent}
    </Modal>
  );

  return (
    <div>
      <img
        width="100%"
        onClick={(e) => handleClick(card)}
        src={process.env.PUBLIC_URL + "/lor/cards/" + card.cardCode + ".png"}
        alt={card.name}
      ></img>
      {modal}
    </div>
  );
}
