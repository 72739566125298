import React from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import Room from "../../components/Room";
import SpinningPage from "./SpinningPage";
import { Menu, Layout } from "antd";
import { useRoomsContext, Room as RoomType } from "../../contexts/RoomsContext";

const { Sider, Content } = Layout;

interface Props {}

const RoomsPage = (props: Props) => {
  return <RoomsRouter></RoomsRouter>;
};

const RoomsRouter = (props: Props) => {
  const {
    initialized,
    rooms,
    fetchRoom,
    addMember,
    sendMessage,
  } = useRoomsContext();

  const history = useHistory();
  let match = useRouteMatch("/rooms/:uid");
  let uid: string | undefined;
  if (match) {
    const params = match.params as { uid: string };
    uid = params.uid;
  }

  if (!initialized) {
    return <SpinningPage />;
  }

  const menuItems = Object.entries(rooms).map(
    ([roomUid, room]: any, i: number) => {
      return (
        <Menu.Item
          key={room.uid}
          onClick={(e) => {
            if (rooms[roomUid].messages === undefined) {
              fetchRoom(roomUid);
            }
            history.push(`/rooms/${roomUid}`);
          }}
        >
          <Link to={`/rooms/${room.uid}`}>
            <span
              style={{ color: room.messages !== undefined ? "green" : "red" }}
            >
              {room.name}
            </span>
          </Link>
        </Menu.Item>
      );
    }
  );

  const selectedKeys = uid ? [uid] : [];

  const sider = (
    <Sider>
      <Menu mode="vertical" selectedKeys={selectedKeys}>
        {menuItems}
      </Menu>
    </Sider>
  );

  let selectedRoom: RoomType | undefined;
  if (uid !== undefined) {
    selectedRoom = rooms[uid];
  }

  return (
    <Layout>
      {sider}
      <Layout style={{ height: "100vh" }}>
        <Content>
          {uid !== undefined ? (
            <Room
              uid={uid!}
              messages={selectedRoom!.messages}
              members={selectedRoom!.members}
              loading={selectedRoom!.messages === undefined}
              onSubmit={(message) => sendMessage(uid!, message)}
              onAddMember={(memberUid) => addMember(uid!, memberUid)}
            ></Room>
          ) : (
            <div>No Room Selected</div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default RoomsPage;
