import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "firebase";
import firebaseConfig from "../firebaseConfig.json";

firebase.initializeApp(firebaseConfig);

interface Claims {
  [key: string]: any;
}

interface CustomClaims extends Claims {
  admin?: boolean;
}

export interface AuthState {
  currentUser?: firebase.User;
  token?: string;
  claims?: CustomClaims;
}

interface AuthContext {
  initialized: boolean;
  auth: AuthState;
  setAuth: (auth: AuthState) => void;
}

const initialAuthState: AuthState = {
  currentUser: undefined,
  token: undefined,
  claims: undefined,
};

const AuthContext = createContext<AuthContext | undefined>(undefined);

const AuthProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);

  const [auth, setAuth] = useState(initialAuthState);

  useEffect(() => {
    if (!initialized) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const tokenResult = await user.getIdTokenResult();
          setAuth({
            currentUser: user,
            token: tokenResult.token,
            claims: tokenResult.claims,
          });
        } else {
          setAuth({
            currentUser: undefined,
            token: undefined,
            claims: undefined,
          });
        }
        setInitialized(true);
      });
    }
  }, [initialized, setInitialized]);

  const value = { initialized, auth, setAuth };
  return <AuthContext.Provider value={value} {...props} />;
};

function useAuthContext() {
  return useContext(AuthContext)!;
}

export { AuthProvider, useAuthContext };
