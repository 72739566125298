export const pick = (object: any, whitelist: string[]) => {
  return whitelist.reduce((acc: any, key) => {
    if (key in object) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
};

export const reject = (object: any, blacklist: string[]) => {
  return Object.keys(object).reduce((acc: any, key) => {
    if (!blacklist.includes(key)) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
};

export const hashCode = (str: string) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export const intToRGB = (i: number) => {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};

// export function debounce<F extends (...params: any[]) => void>(
//   fn: F,
//   delay: number
// ) {
//   let timeoutID: number = null;
//   return function (this: any, ...args: any[]) {
//     clearTimeout(timeoutID);
//     timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
//   } as F;
// }

export function debounce(func: Function, wait: number, immediate: boolean) {
  let timeout: NodeJS.Timeout | undefined;
  return function (this: any) {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
