import React, { PropsWithChildren, useState } from "react";
import { Layout, Menu, Button } from "antd";
import { routesConfig, getRouteConfigTo, RouteConfig } from "../Router";
import { Link, useLocation } from "react-router-dom";
import { QuestionCircleOutlined, PoweroffOutlined } from "@ant-design/icons";
import firebase, { app } from "firebase";
// import useAuth, { AuthState } from "../../hooks/useAuth";
import { useAuthContext } from "../../contexts/AuthContext";
import { useAppContext } from "../../contexts/AppContext";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

interface Props {
  style?: React.CSSProperties;
}

const SiderLayout = (props: PropsWithChildren<Props>) => {
  // const [collapsed, setCollapsed] = useState(false);
  const { auth } = useAuthContext();
  const { app, setCollapsed } = useAppContext();

  const location = useLocation();

  const noRedirectsRouteConfigs = routesConfig.filter(
    (rc) => rc.redirect === undefined
  );

  let nonAdminRouteConfigs = noRedirectsRouteConfigs.filter(
    (rc) => !rc.adminRequired && rc.authenticatedRequired
  );

  let adminRouteConfigs = noRedirectsRouteConfigs.filter(
    (rc) => rc.adminRequired
  );

  const nonAdminMenuItems = nonAdminRouteConfigs.map((rc) =>
    routeConfigToMenuItem(rc)
  );

  const adminMenuItems = adminRouteConfigs.map((rc) =>
    routeConfigToMenuItem(rc)
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={app.collapsed}
        onCollapse={(c, t) => setCollapsed(c)}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          defaultSelectedKeys={[location.pathname]}
          mode="inline"
        >
          {nonAdminMenuItems}
          {auth.claims?.admin ? (
            <SubMenu
              key="admin"
              title={
                <span>
                  <QuestionCircleOutlined />
                  <span>Admin Routes</span>
                </span>
              }
            >
              {adminMenuItems}
            </SubMenu>
          ) : undefined}
          <Menu.Item
            onClick={() => {
              firebase.auth().signOut();
            }}
            key="sign-out-btn"
          >
            {app.collapsed ? (
              <div>
                <PoweroffOutlined style={{ color: "red" }} />
                <span>Sign Out</span>
              </div>
            ) : (
              <Button
                style={{ width: "100%" }}
                type="primary"
                size="small"
                danger
              >
                Sign Out
              </Button>
            )}
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ width: "100%" }}>
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

const routeConfigToMenuItem = (routeConfig: RouteConfig) => {
  const to = getRouteConfigTo(routeConfig);

  return (
    <Menu.Item key={to}>
      <Link to={to}>
        {routeConfig.icon ? <routeConfig.icon /> : <QuestionCircleOutlined />}
        <span>{routeConfig.displayName ? routeConfig.displayName : to}</span>
      </Link>
    </Menu.Item>
  );
};

export default SiderLayout;
