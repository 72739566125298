import { useState, useCallback, useEffect } from "react";

// Hook
export const useAsync = (
  asyncFunction: () => Promise<any>,
  immediate = true
) => {
  const [pending, setPending] = useState(true);
  const [value, setValue] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(async () => {
    setPending(true);
    setValue(undefined);
    setError(undefined);
    try {
      const response = await asyncFunction();
      setValue(response);
    } catch (err) {
      console.log("err :", err);
      setError(err);
    } finally {
      setPending(false);
    }
    // await asyncFunction()
    //   .then(response => setValue(response))
    //   .catch(error => setError(error))
    //   .finally(() => setPending(false));
  }, [asyncFunction]);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
};

export default useAsync;
