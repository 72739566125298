import React from "react";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";

interface Props {}

const ForgotPasswordPage = (props: Props) => {
  const onSubmit = (values: { email: string }) => {
    console.log("values :", values);
  };
  return (
    <ForgotPasswordForm
      style={{ margin: "auto" }}
      onSubmit={onSubmit}
    ></ForgotPasswordForm>
  );
};

export default ForgotPasswordPage;
