import React, { createContext, useContext, useState, useEffect } from "react";
import produce from "immer";

import Set1JSON from "../components/LoR/set1-en_us.json";
import Set1GlobalsJSON from "../components/LoR/globals-en_us.json";

export const cardArrayCollectible = [...Set1JSON].reduce((acc, cur) => {
  if (cur.collectible) {
    acc.push(cur);
  }
  return acc;
}, [] as LoRCard[]);

// Map of all Cards indexed by cardCode
export const cardMap = [...Set1JSON].reduce((total, currentCard) => {
  total[currentCard.cardCode] = currentCard;
  return total;
}, Object.create({}) as { [key: string]: LoRCard });

// Array of all Regions
export const regions = Set1GlobalsJSON.regions.reduce(
  (total, currentRegion) => {
    total.push(currentRegion.nameRef);
    return total;
  },
  [] as string[]
);

// Array of all Keywords
export const keywords = Set1GlobalsJSON.keywords.reduce(
  (total, currentKeyword) => {
    total.push(currentKeyword.nameRef);
    return total;
  },
  [] as string[]
);

export interface LoRState {
  filteredCards: LoRCard[];
}

export interface LoRCard {
  associatedCards: any[];
  associatedCardRefs: string[];
  assets: LoRCardAsset[];
  region: string;
  regionRef: string;
  attack: number;
  cost: number;
  health: number;
  description: string;
  descriptionRaw: string;
  levelupDescription: string;
  levelupDescriptionRaw: string;
  flavorText: string;
  artistName: string;
  name: string;
  cardCode: string;
  keywords: string[];
  keywordRefs: string[];
  spellSpeed: string;
  spellSpeedRef: string;
  rarity: string;
  rarityRef: string;
  subtype: string;
  subtypes?: string[];
  supertype: string;
  type: string;
  collectible: boolean;
}

interface LoRCardAsset {
  gameAbsolutePath: string;
  fullAbsolutePath: string;
}

interface LoRContext {
  initialized: boolean;
  loR: LoRState;
  setLoR: (loR: LoRState) => void;
}

const initialLoRState: LoRState = {
  filteredCards: [],
};

const LoRContext = createContext<LoRContext | undefined>(undefined);

const LoRProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [loR, setLoR] = useState(initialLoRState);
  useEffect(() => {
    if (!initialized) {
      setLoR((curLoR) =>
        produce(curLoR, (draft) => {
          draft.filteredCards = cardArrayCollectible;
        })
      );

      sortCards();
      setInitialized(true);
    }
  }, [initialized, setInitialized]);

  const sortCards = (by = "cost") => {
    let sortedCards: LoRCard[];
    switch (by) {
      case "name":
        sortedCards = [...cardArrayCollectible].sort(sortByName);
        break;

      case "cost":
        sortedCards = [...cardArrayCollectible].sort(sortByCost);
        break;

      default:
        sortedCards = [...cardArrayCollectible].sort(sortByName);
        break;
    }
    setLoR((curLoR) =>
      produce(curLoR, (draft) => {
        draft.filteredCards = sortedCards;
      })
    );
  };

  const value = { initialized, loR, setLoR };
  return <LoRContext.Provider value={value} {...props} />;
};

const sortByName = (a: any, b: any) => {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const sortByCost = (a: any, b: any) => {
  var costA = a.cost;
  var costB = b.cost;
  if (costA < costB) {
    return -1;
  }
  if (costA > costB) {
    return 1;
  }

  // names must be equal
  return 0;
};

function useLoRContext() {
  return useContext(LoRContext)!;
}

export { LoRProvider, useLoRContext };
