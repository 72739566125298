import React, { ReactElement } from "react";
import { Form, Input, Button } from "antd";

import { SafetyCertificateOutlined, UserOutlined } from "@ant-design/icons";
interface Props {
  style: React.CSSProperties;
  onSubmit: (values: { email: string }) => void;
}

export default function ForgotPasswordForm(props: Props): ReactElement {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    props.onSubmit(values);
  };

  return (
    <Form
      style={{ width: "400px", ...props.style }}
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input your email." }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          <SafetyCertificateOutlined />
          Reset password
        </Button>
      </Form.Item>
      <a className="login-form-forgot" href="/">
        Home
      </a>
    </Form>
  );
}
