import React, { createContext, useContext, useState, useEffect } from "react";
import produce from "immer";
export interface AppState {
  collapsed: boolean;
}
interface AppContext {
  initialized: boolean;
  app: AppState;
  setCollapsed: (collapsed: boolean) => void;
}
const initialAppState: AppState = {
  collapsed: false,
};
const AppContext = createContext<AppContext | undefined>(undefined);
const AppProvider = (props: any) => {
  const [initialized, setInitialized] = useState(false);
  const [app, setApp] = useState(initialAppState);
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }
  }, [initialized, setInitialized]);

  const setCollapsed = (collapsed: boolean) => {
    setApp((curApp) =>
      produce(curApp, (draft) => {
        draft.collapsed = collapsed;
      })
    );
  };

  const value = { initialized, app, setCollapsed };
  return <AppContext.Provider value={value} {...props} />;
};
function useAppContext() {
  return useContext(AppContext)!;
}
export { AppProvider, useAppContext };
