import React from "react";
import NewUserForm from "../../components/NewUserForm";
import Axios from "axios";
// import useAuth from "../../hooks/useAuth";
import { useAuthContext } from "../../contexts/AuthContext";
import { message } from "antd";

interface Props {}

const NewUserPage = (props: Props) => {
  const {
    auth: { token },
  } = useAuthContext();

  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      const response = await Axios.post(`/api/users`, values, {
        headers: { Authorization: "Bearer " + token },
      });
      if (response.status === 201) {
        console.log("Successfully created new user");
        message.success("Success");
      }
    } catch (error) {
      const errJSON = error.toJSON();
      console.error(errJSON);
      if (error.response) {
        message.error(error.response.data);
      } else {
        message.error(errJSON.message);
      }
    }
  };

  return (
    <NewUserForm style={{ margin: "auto" }} onSubmit={onSubmit}></NewUserForm>
  );
};

export default NewUserPage;
