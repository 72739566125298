import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Profile from "../../components/Profile";
import Axios from "axios";
import SpinningPage from "./SpinningPage";
// import { useAuth, CustomClaims } from "../../hooks/useAuth";
import { pick } from "../../util";
import useAsync from "../../hooks/useAsync";
import { useAuthContext } from "../../contexts/AuthContext";

interface Props {}

const UserPage = (props: Props) => {
  const { auth } = useAuthContext();
  const { currentUser, token, claims } = auth;
  let { uid } = useParams();

  let customClaims;

  if (uid === "me") {
    uid = currentUser?.uid;
    customClaims = pick(claims, ["admin"]);
  }

  const fetchUser = useCallback(async () => {
    const response = await Axios.get(`/api/users/${uid}`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }, [uid, token]);

  const { pending, value, error } = useAsync(fetchUser);

  if (pending || error) {
    return <SpinningPage />;
  }

  const user = { ...value, ...customClaims };
  return <Profile user={user} />;
};

export default UserPage;
