import React, { useState, useEffect, useRef } from "react";
import { List, Avatar, Tooltip, Comment, Layout, Input, Tag } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import { intToRGB, hashCode } from "../util";
import AddMemberModal from "../containers/AddMemberModal";
import SpinningPage from "../containers/pages/SpinningPage";

const { Content, Footer, Header } = Layout;

type MemberUid = string;

interface Message {
  uid: string;
  text: string;
  from: MemberUid;
  dateSent: {
    _seconds: number;
    _nanoseconds: number;
  };
}

interface Member {
  email: string;
  uid: MemberUid;
  role: string;
  photoURL?: string;
  displayName?: string;
}

interface Props {
  uid: string;
  loading: boolean;
  members?: { [key: string]: Member };
  messages?: Message[];
  onSubmit: (message: { text: string }) => void;
  onAddMember: (memberUid: string) => Promise<void>;
}

const Room = (props: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [messageText, setMessageText] = useState("");
  const contentRef: any = useRef(null);

  useEffect(() => {
    setMessageText("");
  }, [props.uid]);

  useEffect(() => {
    if (contentRef !== null && !props.loading) {
      const out = contentRef.current;
      const isScrolledToBottom =
        out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
      if (!isScrolledToBottom) {
        out.scrollTop = out.scrollHeight - out.clientHeight;
      }
    }
  }, [props.messages, contentRef, props.loading]);

  if (props.loading) {
    return <SpinningPage></SpinningPage>;
  }

  const chatMessages = (
    <List
      className="message-list"
      ref={contentRef}
      size="small"
      bordered
      loading={props.loading}
      dataSource={props.messages}
      renderItem={(message) => {
        const dateTooltip = (
          <Tooltip
            title={moment
              .unix(message.dateSent._seconds)
              .format("YYYY-MM-DD HH:mm:ss")}
          >
            <span>{moment.unix(message.dateSent._seconds).fromNow()}</span>
          </Tooltip>
        );

        const hasPhoto = props.members![message.from].photoURL;
        const hasDisplayName = props.members![message.from].displayName;
        return (
          <List.Item key={message.uid} className="message-list-item">
            <Comment
              author={
                <Link to={`/users/${message.from}`}>
                  {hasDisplayName
                    ? props.members![message.from].displayName
                    : props.members![message.from].email}
                </Link>
              }
              avatar={
                <Link to={`/users/${message.from}`}>
                  <Avatar
                    src={hasPhoto}
                    style={{
                      backgroundColor: "#" + intToRGB(hashCode(message.from)),
                      border: hasPhoto
                        ? "2px dotted #" + intToRGB(hashCode(message.from))
                        : undefined,
                    }}
                  >
                    {hasPhoto
                      ? undefined
                      : props.members![
                          message.from
                        ].email[0].toLocaleUpperCase()}
                  </Avatar>
                </Link>
              }
              content={<p>{message.text}</p>}
              datetime={dateTooltip}
            ></Comment>
          </List.Item>
        );
      }}
    />
  );

  return (
    <Layout style={{ height: "100vh" }}>
      <Header>
        <span>Members: </span>
        {Object.entries(props.members!)
          .sort(([uidA], [uidB]) => {
            return uidA.localeCompare(uidB);
          })
          .map(([memberUid, member]: any) => {
            return (
              <Tag
                key={`tag-${memberUid}`}
                color={"#" + intToRGB(hashCode(memberUid))}
              >
                <Link to={`/users/${memberUid}`}>
                  {member.displayName ? member.displayName : member.email}
                </Link>
              </Tag>
            );
          })}
        <Tag onClick={(e) => setModalVisible(true)}>+</Tag>
      </Header>

      <div ref={contentRef} style={{ height: "100%", overflow: "scroll" }}>
        <Content>
          {chatMessages}
          <AddMemberModal
            roomUid={props.uid}
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            onAddMember={async (memberUid) => {
              await props.onAddMember(memberUid);
              setModalVisible(false);
            }}
          />
        </Content>
      </div>
      <Footer style={{ backgroundColor: "#dee0e3" }}>
        <Input.Search
          style={{
            position: "sticky",
            bottom: 0,
          }}
          enterButton="Send"
          size="large"
          disabled={props.loading}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Type a new message here"
          onSearch={(value, e) => {
            setMessageText("");
            props.onSubmit({ text: value });
          }}
        />
      </Footer>
    </Layout>
  );
};

export default Room;
