import React, { useState } from "react";
import NewRoomForm from "../../components/NewRoomForm";
import { useRoomsContext } from "../../contexts/RoomsContext";

interface Props {}

const NewRoomPage = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const roomsContext = useRoomsContext();
  const { addRoom } = roomsContext;

  const onSubmit = async (values: { name: string }) => {
    setLoading(true);
    await addRoom(values);
    setLoading(false);
  };

  return (
    <NewRoomForm
      style={{ margin: "auto" }}
      loading={loading}
      onSubmit={onSubmit}
    ></NewRoomForm>
  );
};

export default NewRoomPage;
