import React, { useCallback } from "react";
import Axios from "axios";
import SpinningPage from "./SpinningPage";
import Profile from "../../components/Profile";
import useAsync from "../../hooks/useAsync";
import { useAuthContext } from "../../contexts/AuthContext";
import { Button } from "antd";

interface User {
  uid?: string;
  email?: string;
}

interface Props {}

const UsersPage = (props: Props) => {
  const { auth } = useAuthContext();
  const { token } = auth;

  const fetchUsers = useCallback(async () => {
    const response = await Axios.get(`/api/users`, {
      headers: { Authorization: "Bearer " + token },
    });
    return response.data;
  }, [token]);

  const { execute, pending, value, error } = useAsync(fetchUsers);

  const deleteUser = useCallback(
    async (userUid) => {
      if (!pending) {
        const response = await Axios.delete(`/api/users/${userUid}`, {
          headers: { Authorization: "Bearer " + token },
        });
        console.log("response :", response);
        execute();
        return response.data;
      }
    },
    [token, execute, pending]
  );

  if (pending || error) {
    return <SpinningPage />;
  }

  const handleDelete = async (user: any) => {
    console.log("Delete user :", user);
    await deleteUser(user.uid);
  };

  return (
    <div>
      {value &&
        value.map((user: any, i: number) => {
          return (
            <div
              key={i}
              style={{
                outline: "2px gray solid",
                margin: "10px",
                padding: "5px",
              }}
            >
              {true ? (
                <Button
                  style={{ float: "right" }}
                  danger
                  onClick={(e) => handleDelete(user)}
                >
                  Delete
                </Button>
              ) : null}
              <Profile user={user}></Profile>
            </div>
          );
        })}
    </div>
  );
};

export default UsersPage;
