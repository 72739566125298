import React from "react";
import Set1GlobalsJSON from "./globals-en_us.json";
import { Tag } from "antd";
import "./styles.css";

interface Props {
  keyword: string;
}
const keywordData = Set1GlobalsJSON.keywords.reduce((acc, cur) => {
  acc[cur.nameRef] = cur;
  switch (cur.nameRef) {
    // case "Obliterate":
    //   break;
    // case "Skill":
    //   break;
    // case "DoubleStrike":
    //   break;
    // case "Weakest":
    //   break;
    // case "AttackSkillMark":
    //   break;
    // case "Elusive":
    //   break;
    // case "Drain":
    //   break;
    // case "Stun":
    //   break;
    // case "Autoplay":
    //   break;
    // case "PiltoverZaun":
    //   break;
    // case "Demacia":
    //   break;
    // case "ShadowIsles":
    //   break;
    // case "SpellOverwhelm":
    //   break;
    // case "Barrier":
    //   break;
    // case "Capture":
    //   break;
    // case "Frostbite":
    //   break;
    case "Burst":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "Fleeting":
    //   break;
    case "Fast":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    case "Overwhelm":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "PlaySkillMark":
    //   break;
    // case "QuickStrike":
    //   break;
    case "Tough":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "Recall":
    //   break;
    // case "Ionia":
    //   break;
    case "Regeneration":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "Silenced":
    //   break;
    // case "Lifesteal":
    //   break;
    // case "Enlightened":
    //   break;
    // case "LevelUp":
    //   break;
    case "Slow":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "Noxus":
    //   break;
    case "Ephemeral":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "Freljord":
    //   break;
    // case "LastBreath":
    //   break;
    case "Challenger":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "Imbue":
    //   break;
    case "Fearsome":
      acc[cur.nameRef].tag = (
        <img
          className={"keyword"}
          src={process.env.PUBLIC_URL + "/lor/keywords/" + cur.nameRef + ".png"}
        />
      );
      break;
    // case "CantBlock":
    //   break;
    default:
      break;
  }
  acc[cur.nameRef] = cur;
  return acc;
}, {} as any);

const Keyword = (props: Props) => {
  console.log("props", props);
  console.log("keywordData :", keywordData);
  const data = keywordData[props.keyword];
  console.log("data :", data);
  if (data.tag !== undefined) {
    return <div>{data.tag}</div>;
  }
  return <div color={data.color}>{data.name}</div>;
};

export default Keyword;
