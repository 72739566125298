import React from "react";
import { Spin } from "antd";

interface Props {}

const SpinningPage = (props: Props) => {
  return (
    <div
      style={{
        height: "100vh",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin />
    </div>
  );
};

export default SpinningPage;
